import { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { Featured } from 'src/constants/types';
import { formatLink } from 'src/utils/featuredPosts';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
import { LinkButton, SecondaryButton } from 'styles-js/buttons';
import { text, textLight, titleMedium } from 'styles-js/mixins/typography';
type Props = {
  disabled?: {
    callToActionUrl?: boolean;
    hideButton?: boolean;
    snippet?: boolean;
    title?: boolean;
  } | Record<string, never>;
  featuredPost: Featured;
  onClick: (e: SyntheticEvent) => void;
  onDismiss: () => void;
};
export default function NormalFeaturedPost({
  disabled,
  featuredPost,
  onClick,
  onDismiss
}: Props) {
  const formattedLink = formatLink(featuredPost.callToActionUrl);
  if (!featuredPost) return null;
  return <>
      {featuredPost.imageUrl && <CampaignLink href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
          <CampaignImg $backgroundImage={featuredPost.imageUrl} />
        </CampaignLink>}

      <TitleContainer data-sentry-element="TitleContainer" data-sentry-source-file="normalFeaturedPost.tsx">
        <Title $isDisabled={disabled?.title} data-sentry-element="Title" data-sentry-source-file="normalFeaturedPost.tsx">
          {disabled?.title ? featuredPost.title : <a href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
                {featuredPost.title}
              </a>}
        </Title>
        <HideButton $isDisabled={disabled?.hideButton} type="button" onClick={onDismiss} data-sentry-element="HideButton" data-sentry-source-file="normalFeaturedPost.tsx">{i18n.t('Hide')}</HideButton>
      </TitleContainer>

      <Body $isDisabled={disabled?.snippet} data-sentry-element="Body" data-sentry-source-file="normalFeaturedPost.tsx">{featuredPost.snippet}</Body>

      {featuredPost.callToActionText && <CtaWrapper>
          {disabled?.callToActionUrl ? <DisabledButton disabled={true} text={featuredPost.callToActionText} /> : <SecondaryButton as="a" href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
                {featuredPost.callToActionText}
              </SecondaryButton>}
        </CtaWrapper>}
      <Footer data-sentry-element="Footer" data-sentry-source-file="normalFeaturedPost.tsx">
        <FooterContent data-sentry-element="FooterContent" data-sentry-source-file="normalFeaturedPost.tsx">{i18n.t('Featured by')} {featuredPost.featuredByCommunityName}</FooterContent>
      </Footer>
    </>;
}
export const CampaignLink = styled.a`
  display: block;
  margin-bottom: 10px;
`;
export const CampaignImg = styled.div<{
  $backgroundImage: string;
}>`
  aspect-ratio: 4/1;
  background-image: ${({
  $backgroundImage
}) => `url(${$backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
`;
export const TitleContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;
export const Title = styled.h3<{
  $isDisabled?: boolean;
}>`
  ${titleMedium};
  margin: 0;
  padding-right: 46px; // hide button width + 10px spacing
  overflow-wrap: break-word;
  a {
    transition: color 0.5s ease;
    color: ${({
  theme
}) => theme.colorBlack};
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorGreyDark};
    }
  }
  ${({
  $isDisabled,
  theme
}) => $isDisabled && css`
    color: ${theme.colorGreyDark};
  `}
`;
export const HideButton = styled(LinkButton)<{
  $isDisabled?: boolean;
}>`
  ${textLight}
  position: absolute;
  top: 0;
  right: 0;
  line-height: ${({
  theme
}) => theme.fontLineHeightM};
  ${({
  $isDisabled,
  theme
}) => $isDisabled && css`
    color: ${theme.colorGreyDark};
  `}
`;
export const Body = styled.div<{
  $isDisabled?: boolean;
}>`
  ${text}
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 5px;
  ${({
  $isDisabled
}) => $isDisabled && css`
    color: ${({
  theme
}) => theme.colorGreyDark};
  `}
`;
export const CtaWrapper = styled.div`
  a {
    margin: 0;
  }
`;
export const DisabledButton = styled(Button)`
  &:disabled {
    background-color: transparent;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const FooterContent = styled.div`
  color: ${({
  theme
}) => theme.colorGreyDark};
`;